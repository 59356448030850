import React from 'react'
import './Spinner.scss'

export function SpinningDots() {
  return (
    <div className='dot-wrap'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export function InlineSpinner() {
  return (
    <div className='spinner inline-spinner'>
      <SpinningDots />
    </div>
  )
}

export default function Spinner({ message }) {
  return (
    <div className='spinner'>
      <SpinningDots />
      {message ? <p className='message'>{message}</p> : ''}
    </div>
  )
}

import React, { useState } from "react";
import "./TriageForm.scss";
import ProfilePicture from '../alex.png';
import { ReactComponent as GreenTick } from '../green-tick.svg';
import { ReactComponent as SecurityLogo } from '../security-logo.svg';
import { InlineSpinner } from './Spinner.js';

export function MainButton(props){
  return <button className={(props.className || "") + ' main-button'} {...props}>
    {props.children}
  </button>
}

export function BackButton(props){
  return <button className={(props.className || "") + ' back-button'} {...props}>
    {props.children || "← Back"}
  </button>
}


const uniqueId = (function () {
  let id = 0;
  return function (prefix) {
    id += 1;
    return `${prefix || ""}${id}`;
  };
})();

const topicValues = {
  afford: false,
  properties: false,
  process: false,
  questions: false,
};

function TriagePageHeader(props){
  return <div 
    {...props}
    className={(props.className || "") + " triage-page-header-wrap"}
    >
    {props.children}
  </div>
}

function TriagePageImage(props){
  return <div 
    {...props}
    className={(props.className || "") + " triage-page-image-wrap"}
    >
    <img src={ProfilePicture} alt=""/>
  </div>
}

function TriagePageBody(props){
  return <div 
    {...props}
    className={(props.className || "") + " triage-page-body-wrap"}
    >
    {props.children}
  </div>
}

function TriagePageButtons({ goBackOnePage, mainButtonText }){
  return <div class='question-page-action-buttons-wrap'>
    <div class='question-page-back-button-wrap'>
      {
        goBackOnePage ?
          <BackButton  onClick={e => {e.preventDefault(); goBackOnePage()}} /> :
          <span></span>
      }
    </div>
    <div class='question-page-submit-button-wrap'>
      <MainButton type="submit">{mainButtonText}</MainButton>
    </div>
  </div>
}

function TriagePageFooter(props){
  return <div {...props} className='triage-page-footer-wrap'>
    {props.children}
  </div>
}



function TopicsHeader({ variant }){
  switch(variant){
    case "tips-and-tricks":
      return <>
        <p>Tell us where you’re at in your journey and your Kettel Guide 🙋‍♀️  will respond within the hour to help you buy your dream home 🏡</p>
      </>
    case "personalised-guide":
      return <>
        <p>Tell us where you’re at in your journey and your Kettel Guide 🙋‍♀️  will respond within the hour to help you buy your dream home 🏡</p>
      </>
    case "help-to-buy":
      return <>
        <p>Let us know any questions you have about the scheme and your Kettel Guide 🙋‍♀️  will respond within the hour to help you buy your dream home 🏡</p>
      </>
    case "financing-your-home":
      return <>
        <p>Let us know any questions and your Kettel Guide 🙋‍♀️  will respond within the hour to help with some personalised guidance about everything from LISAs to Fixed Rate Mortgages! 🙂</p>
      </>
    case "government-incentives":
      return <>
        <p>Tell us where you’re at in your journey and your Kettel Guide 🙋‍♀️  will respond within the hour to help you buy your dream home 🏡</p>
      </>
    case "what-to-know":
    default:
      return <>
        <p>Tell us where you’re at in your journey and your Kettel Guide 🙋‍♀️  will respond within the hour to help you buy your dream home 🏡</p>
      </>
  }
}



export function QuestionTopics({ variant, topics: _topics, submitTopics }) {
  let [id] = useState(uniqueId("question-topics-"));
  let [topics, setTopics] = useState(_topics || { ...topicValues });
  let [error, setError] = useState(false);
  let validate = () => {
    // At least one must be selected
    return Object.values(topics).reduce((prev, curr) => prev || curr, false);
  }
  let onClickListElementFactory = topic => e => {
    setTopics(prevTopics => ({ ...prevTopics, [topic]: !prevTopics[topic] }));
  }
  let onChangeTopic = (e) => {
    let topic = e.target.value;
    let checked = e.target.checked;
    setTopics({ ...topics, [topic]: checked });
  };
  let onSubmit = (e) => {
    e.preventDefault();
    setError(false);
    if ( !validate() ){
      setError(true);
      return;
    }
    submitTopics(topics);
  };
  return (
    <form onSubmit={onSubmit}>
      <div className="question-topics triage-page">
        <TriagePageHeader>
          <TopicsHeader variant={variant}/>
        </TriagePageHeader>
        <TriagePageImage/>
        <TriagePageBody className='triage-question'>
          <ul>
            <li>
              <div className="input-wrap">
                <input
                  checked={topics.afford === true }
                  type="checkbox"
                  value="afford"
                  name="topic"
                  onChange={onChangeTopic}
                  id={id + "-afford"}
                />
              </div>
              <div className="label-wrap">
                <label htmlFor={id + "-afford"}>
                  I want to know what I can <b>afford</b> or how best to save.
                </label>
              </div>
              <button 
                type="button"
                className='cover-background'
                onClick={onClickListElementFactory('afford')}>
              </button>
            </li>
            <li>
              <div className="input-wrap">
                <input
                  checked={topics["properties"]}
                  type="checkbox"
                  value="properties"
                  name="topic"
                  onChange={onChangeTopic}
                  id={id + "-properties"}
                />
              </div>
              <div className="label-wrap">
                <label htmlFor={id + "-properties"}>
                  I’m thinking about <b>properties and locations</b>
                </label>
              </div>
              <button 
                type="button"
                className='cover-background'
                onClick={onClickListElementFactory('properties')}>
              </button>
            </li>
            <li>
              <div className="input-wrap">
                <input
                  checked={topics["process"]}
                  type="checkbox"
                  value="process"
                  name="topic"
                  onChange={onChangeTopic}
                  id={id + "-process"}
                />
              </div>
              <div className="label-wrap">
                <label htmlFor={id + "-process"}>
                  I want to know about the <b>process</b>
                </label>
              </div>
              <button 
                type="button"
                className='cover-background'
                onClick={onClickListElementFactory('process')}>
              </button>
            </li>
            <li>
              <div className="input-wrap">
                <input
                  checked={topics["questions"]}
                  type="checkbox"
                  value="questions"
                  name="topic"
                  onChange={onChangeTopic}
                  id={id + "-questions"}
                />
              </div>
              <div className="label-wrap">
                <label htmlFor={id + "-questions"}>
                  I have more <b>questions</b>
                </label>
              </div>
              <button 
                type="button"
                className='cover-background'
                onClick={onClickListElementFactory('questions')}>
              </button>
            </li>
          </ul>
          {
            error ?
              <p className='error'>
                ↑ Please chose a topic area
              </p> :
              null
          }
        </TriagePageBody>
        <TriagePageButtons mainButtonText="Next question"/>
      </div>
    </form>
  );
}




const buyingGoalValues = [
  '3-months',
  '6-months',
  '12-months',
  'just-looking'
]
export function QuestionBuyingGoal({ goBackOnePage, buyingGoal: _buyingGoal, submitBuyingGoal }) {
  let [id] = useState(uniqueId("question-buying-goal-"));
  let [buyingGoal, setBuyingGoal] = useState(_buyingGoal);
  let [error, setError] = useState(false);
  let validate = () => {
    return buyingGoalValues.indexOf(buyingGoal) !== -1;
  }

  let onClickListElementFactory = goal => e => {
    setBuyingGoal(goal)
  }
  let onSelectBuyingGoal = (e) => {
    setBuyingGoal(e.target.value)
  };
  let onSubmit = (e) => {
    e.preventDefault();
    setError(false);
    if ( !validate() ){
      setError(true);
      return;
    }
    submitBuyingGoal(buyingGoal);
  };
  return (
    <form onSubmit={onSubmit}>
      <div className="question-buying-goal triage-page">
        <TriagePageHeader>
          <p>Great. To help us narrow down how we can provide more accurate guidance, <b>what best describes your ambition for buying your first home?</b></p>
        </TriagePageHeader>
        <TriagePageImage/>
        <TriagePageBody className='triage-question'>
          <ul>
          <li>
              <div className="input-wrap">
                <input
                  checked={buyingGoal === '3-months'}
                  type="radio"
                  value="3-months"
                  name="buying-goal"
                  onChange={onSelectBuyingGoal}
                  id={id + "-3-months"}
                />
              </div>
              <div className="label-wrap">
                <label htmlFor={id + "-3-months"}>
                  I want to buy somewhere in the next <b>3 months</b>.
                </label>
              </div>
              <button 
                type="button"
                className='cover-background'
                onClick={onClickListElementFactory('3-months')}>
              </button>
            </li>
            <li>
              <div className="input-wrap">
                <input
                  checked={buyingGoal === '6-months'}
                  type="radio"
                  value="6-months"
                  name="buying-goal"
                  onChange={onSelectBuyingGoal}
                  id={id + "-6-months"}
                />
              </div>
              <div className="label-wrap">
                <label htmlFor={id + "-6-months"}>
                  I want to buy somewhere in the next <b>6 months</b>.
                </label>
              </div>
              <button 
                type="button"
                className='cover-background'
                onClick={onClickListElementFactory('6-months')}>
              </button>
            </li>
            <li>
              <div className="input-wrap">
                <input
                  checked={buyingGoal === '12-months'}
                  type="radio"
                  value="12-months"
                  name="buying-goal"
                  onChange={onSelectBuyingGoal}
                  id={id + "-12-months"}
                />
              </div>
              <div className="label-wrap">
                <label htmlFor={id + "-12-months"}>
                  I want to buy somewhere in the next <b>12 months</b>.
                </label>
              </div>
              <button 
                type="button"
                className='cover-background'
                onClick={onClickListElementFactory('12-months')}>
              </button>
            </li>
            <li>
              <div className="input-wrap">
                <input
                  checked={buyingGoal === 'just-looking'}
                  type="radio"
                  value="just-looking"
                  name="buying-goal"
                  onChange={onSelectBuyingGoal}
                  id={id + "-just-looking"}
                />
              </div>
              <div className="label-wrap">
                <label htmlFor={id + "-just-looking"}>
                  I’m just thinking about <b>options</b>.
                </label>
              </div>
              <button 
                type="button"
                className='cover-background'
                onClick={onClickListElementFactory('just-looking')}>
              </button>
            </li>
          </ul>
          {
            error ?
              <p className='error'>
                ↑ Please chose a goal
              </p> :
              null
          }
        </TriagePageBody>
        <TriagePageButtons goBackOnePage={goBackOnePage} mainButtonText="Next question"/>
      </div>
    </form>
  );
}


const defaultLookingFor = {
  message: "",
  selectedOther: false
}
export function QuestionLookingFor({ goBackOnePage, lookingFor: _lookingFor, submitLookingFor }) {
  let [id] = useState(uniqueId("question-looking-for-"));
  let [lookingFor, setLookingFor] = useState(_lookingFor || {...defaultLookingFor});
  let [error, setError] = useState(false);
  
  let onSelectOther = e => {
    setLookingFor({...lookingFor, selectedOther: e.target.checked});
  };
  
  let onChangeMessage = e => {
    let value = e.target.value;
    let selectedOther = value !== "" ? false : lookingFor.selectedOther;
    setLookingFor({...lookingFor, selectedOther, message: e.target.value});
  }
  let validate = () => {
    // At least one must be selected
    return lookingFor.message !== "" || lookingFor.selectedOther;
  }
  let onSubmit = e => {
    e.preventDefault();
    setError(false);
    if ( !validate() ){
      setError(true);
      return
    }
    submitLookingFor(lookingFor);
  };
  return (
    <form onSubmit={onSubmit}>
      <div className="question-looking-for triage-page">
        <TriagePageHeader>
          <p>Do you have a question or issue in mind that we can help with?</p>
        </TriagePageHeader>
        <TriagePageImage/>
        <TriagePageBody className='triage-question'>
          <div className='message-wrap'>
            <textarea 
              value={lookingFor.message} 
              onChange={onChangeMessage} 
              placeholder="e.g. Working out where to buy to get good transport links...">
            </textarea>
          </div>
          <ul>
            <li>
              <div className="input-wrap">
                <input
                  checked={lookingFor.selectedOther}
                  type="checkbox"
                  name="selected-other"
                  onChange={onSelectOther}
                  id={id + "-selected-other"}
                />
              </div>
              <div className="label-wrap">
                <label htmlFor={id + "-selected-other"}>
                  Not at the moment
                </label>
              </div>
              <button 
                type="button"
                className='cover-background'
                onClick={() => {
                  setLookingFor(prevLookingFor => {
                    return {...prevLookingFor, selectedOther: !prevLookingFor.selectedOther}
                  })
                }}>
              </button>
            </li>
          </ul>
          {
            error ?
              <p className='error'>
                ↑ Let us know if there's anything specific we can help with
              </p> :
              null
          }
        </TriagePageBody>
        <TriagePageButtons goBackOnePage={goBackOnePage} mainButtonText="Next question"/>
      </div>
    </form>
  );
}



export function ValuePropositionSummary({ goBackOnePage, onContinue }) {
  let onSubmit = e => {
    e.preventDefault();
    onContinue();
  };
  return (
    <form onSubmit={onSubmit}>
      <div className="value-proposition-summary-page triage-page">
        <TriagePageHeader>
          <p><b>Great.</b> Here’s 3 things we can do for you for free:</p>
        </TriagePageHeader>
        <TriagePageImage/>
        <TriagePageBody>
          <ul>
            <li>
              <div className='value-proposition-tick-wrap'>
                <GreenTick/>
              </div>
              <div className='value-proposition-text-wrap'>
                <h2>Your own dedicated Kettel guide</h2>
                <p>Chat with us on your time, we can provide help with any questions you have, 24/7</p>
              </div>
            </li>
            <li>
              <div className='value-proposition-tick-wrap'>
                <GreenTick/>
              </div>
              <div className='value-proposition-text-wrap'>
                <h2>Personalised guidance</h2>
                <p>We'll find resources and options that are specifically tailored to you based on what you tell us.</p>
              </div>
            </li>
            <li>
              <div className='value-proposition-tick-wrap'>
                <GreenTick/>
              </div>
              <div className='value-proposition-text-wrap'>
                <h2>Proactive research</h2>
                <p>We can research options for savings, places to buy, and other services. No hiden incentives.</p>
              </div>
            </li>
          </ul>
        </TriagePageBody>
        <TriagePageButtons goBackOnePage={goBackOnePage} mainButtonText="One last step"/>
      </div>
    </form>
  );
}


const defaultContactDetails = {
  name: '',
  number: '',
  email: '',
  preferredMethod: {
    whatsapp: false,
    text: false,
    email: true
  }
}

const defaultErrors = {
  nameMissing: false,
  numberMissing: false,
  emailMissing: false,
  preferredMethodMissing: false
}

export function AskContactDetails({ goBackOnePage, sendDataStatus, contactDetails: _contactDetails, submitContactDetails }) {
  let [errors, setErrors] = useState({...defaultErrors});
  let [contactDetails, setContactDetails] = useState(
    _contactDetails ?
      {...defaultContactDetails, ..._contactDetails} :
      {...defaultContactDetails}
  );
  let validate = () => {
    let newErrors = {...defaultErrors};
    // Check if fields are empty
    [
      ['name', 'nameMissing'],
      // ['number', 'numberMissing'],
      ['email', 'emailMissing'],
    ].forEach(([field, error]) => {
      newErrors[error] = contactDetails[field] === '';
    });
    // Check if preferred method is missing
    newErrors.preferredMethodMissing = !Object.values(contactDetails.preferredMethod)
      .reduce((prev, curr) => prev || curr, false);
    return newErrors;
  }
  let onSubmit = e => {
    e.preventDefault();
    setErrors({...defaultErrors});
    let newErrors = validate();
    let invalid = Object.values(newErrors).reduce((prev, curr) => prev || curr, false);
    if ( invalid ) {
      setErrors(newErrors);
      return;
    }
    submitContactDetails(contactDetails);
  };
  let onChangeContactDetailFactory = detail => e => {
    setContactDetails({...contactDetails, [detail]: e.target.value})
  }
  return (
    <form onSubmit={onSubmit}>
      <div className="ask-contact-details-page triage-page">
        <TriagePageHeader>
          <p>Great. Let us know your email and one of our Kettel Guides will be in touch.</p>
          <p><b>Our first email comes jam-packed with some great guidance!</b></p>
        </TriagePageHeader>
        <TriagePageImage/>
        <TriagePageBody className='ask-contact-details triage-question'>
          <div className='input-wrap'>
            <label>
              <p className='input-label-text'>Your name</p>
              <p>
                <input type='text' placeholder='e.g. Tom' value={contactDetails.name || ""}
                  onChange={onChangeContactDetailFactory('name')}/>
              </p>
              {
                errors.nameMissing ?
                  <p className='error'>
                    ↑ What shall we call you?
                  </p> :
                  null
              }
            </label>
          </div>
          <div className='input-wrap'>
            <label>
              <p className='input-label-text'>Your email</p>
              <p>
                <input type='email' autoComplete='email' placeholder='e.g. tom@example.com' value={contactDetails.email || ""}
                  onChange={onChangeContactDetailFactory('email')}/>
              </p>
              {
                errors.emailMissing ?
                  <p className='error'>
                    ↑ What is your email?
                  </p> :
                  null
              }
            </label>
          </div>
          <div className='method-options-wrap'>
            {
            sendDataStatus === 'failure' ?
              <div className='sending-status'  data-status="failure">
                <p>❗️ Sorry... that didn't work. We're looking into it. If you're happy to, please try again.</p>
              </div> :
              null
            }
          </div>
        </TriagePageBody>
        <TriagePageButtons
          goBackOnePage={goBackOnePage}
          mainButtonText={
            sendDataStatus === 'pending' ?
              <InlineSpinner/> :
              "Let's go"
            }
          />
        
        <TriagePageFooter>
          <h1>
            <SecurityLogo/>
            <span>Privacy guaranteed</span>
          </h1>
          <p>We take your privacy seriously. We will never sell your data, and our Kettel guides will keep your information completely confidential.</p>
        </TriagePageFooter>
      </div>
    </form>
  );
}


let resources = {
    'help-to-buy': []
}
resources.default = resources['help-to-buy'];


export function SuccessPage({ variant }) {
  return <div className='success-page'>
    <h1>
      <GreenTick/>
      <span>All done! 🎉</span>
    </h1>
    <p>One of our Kettel Guides will be in touch within 12 hours to help you on your journey.</p>
    <p>Sit back, relax, we’ve got it covered ☕️</p>
    {/* <p class='small-text'>If relaxing just isn’t your thing... check out the following resources. These are some of the nicest around:</p> */}
    {/* <ResourceList variant={variant} /> */}
  </div>
}

export function ResourceList({variant}){
  const getResourcesForVariant = () => {
    let _resources = resources[variant];
    if ( !_resources ) {
      return resources.default;
    }
    return _resources;
  }
  return <ul className='resource-list'>
    {
      getResourcesForVariant().map((resource, ix) => {
        return <li key={ix}>
          <div>
            <img src={resource.imgSrc} alt=''/>
          </div>
          <div>
            <h2>{resource.title}</h2>
            <p>{resource.description}</p>
            <p></p>
          </div>
          <a aria-label={"Link to "+resource.title} href={resource.href} target="_blank" rel="noreferrer">
            <i aria-hidden="true"/>
          </a>
        </li>
      })
    }
  </ul>
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LogRocket from 'logrocket';
LogRocket.init('kfz5k3/kettel');

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path={["/:variant", "", "/"]}>
          <App />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// Monitoring: https://bit.ly/CRA-vitals
function sendToAnalytics({ id, name, value }) {
  try {
    window.gtag('send', 'event', {
      eventCategory: 'Web Vitals',
      eventAction: name,
      eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      eventLabel: id, // id unique to current page load
      nonInteraction: true, // avoids affecting bounce rate
    });
  } catch (error){
    console.error(error);
  }
}
reportWebVitals(sendToAnalytics);

import React, { useState, useEffect } from 'react';
import './App.scss';
import { ReactComponent as Logo } from './short-logo.svg';
// import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as PlainLogo } from './plain-logo.svg';
// import { InlineSpinner } from './components/Spinner.js';
import { useParams } from 'react-router-dom';
import LogRocket from 'logrocket';
import { v4 as uuidv4 } from 'uuid';

import { 
  QuestionTopics, 
  QuestionBuyingGoal, 
  QuestionLookingFor, 
  ValuePropositionSummary, 
  AskContactDetails,
  SuccessPage
} from './components/TriageForm.js';

const kettelEnvironment = process.env.REACT_APP_KETTEL_ENVIRONMENT  || 'testing' ;
const pipedreamURLs = {
  'testing': 'https://enu2e8hxvpr6xcw.m.pipedream.net',
  'staging': 'https://enu2e8hxvpr6xcw.m.pipedream.net',
  'production': 'https://enu2e8hxvpr6xcw.m.pipedream.net'
}

const pipedreamURL = pipedreamURLs[kettelEnvironment]
  || pipedreamURLs['staging'];

function Header(){
  return <div className='header-wrap' id='contact-us-header'>
    <Logo />
    <a href='#who-are-kettel'>Who are Kettel? ↓</a>
  </div>
}

function AboutUsHeader(){
  return <div className='header-wrap' id='about-us-header'>
    <span></span>
    <a href='#root'>Ask us for support ↑</a>
  </div>
}


function logGoogleAdsConversion() {
  window.gtag('event', 'conversion', {
      'send_to': 'AW-460966147/qQ11CJGKwvQBEIOS59sB'
  });
}

function googleAnalyticsHeartbeat(){
  let duration = 500;
  beat(0);
  function beat(x){
    setTimeout(() => {
      try {
        window.gtag('event', 'heartbeat');
        duration += x;
        beat(duration);
      } catch(err){}
    }, x)
  }
}

async function sendContactDataToPipedream(data){
  let headers = new Headers();
  headers.append("Content-Type", "application/json")
  const options = {
      method: "POST",
      headers,
      mode: "no-cors", //cors
      body: JSON.stringify(data),
  }
  return fetch(pipedreamURL, options);
}


function HeroSection({ variant }){
  switch(variant){
    case "tips-and-tricks":
      return <div className='hero-section'>
        <h2>We’ve got loads of tips and tricks to help make your homebuying experience a great one.</h2>
      </div>
    case "personalised-guide":
      return <div className='hero-section'>
        <h2>We've made some great first time home buyer guides to help you navigate the experience.</h2>
      </div>
    case "help-to-buy":
      return <div className='hero-section'>
        <h2>Help to buy is a great option for first time buyers.</h2>
      </div>
    case "financing-your-home":
      return <div className='hero-section'>
        <h2>Saving and financing your first home is always a tricky one.</h2>
      </div>
    case "government-incentives":
      return <div className='hero-section'>
        <h2>The UK government has created some great incentives for first time buyers.</h2>
      </div>
    case "what-to-know":
    default:
      return <div className='hero-section'>
        <h2>We know buying your dream home can be confusing.<br/><br/>We’re here to help.</h2>
      </div>
  }
}

function AboutUsSection(){
  return <div className='about-us-section' id='who-are-kettel'>
    <div className="column">
      <AboutUsHeader/>
      <h1>About us</h1>
      <p>Hi! We're Trevor, Stacy and Alex <span className='wave'>👋</span></p>
      <p>We provide personalised and independent guidance to help people buy their first home.</p>
      <p>We call it <PlainLogo width="56" height="14"/>.</p>
      <p>We've heard from so many people that the process of buying your first home is stressful, costly and takes ages.</p>
      <p>We're doing our bit to help change that: we’re available 24/7 to answer questions... <b>for free</b> 😀</p>
      <p className='ask-anything'><b>Ask us anything!</b></p>
      <p className='small-text'>Powered by AI, but with a human touch 👋</p>
    </div>
  </div>
}

function FooterSection(){
  return <div className='footer-section' id='footer'>
    <div className="column">
      <p>© Kettel (trading name of <b>We Are Kettel Ltd</b>; company number <a href='https://find-and-update.company-information.service.gov.uk/company/13051220' target="_blank" rel="noreferrer">13051220</a>)</p>
      <ul>
        <li><a target="_blank" rel="noreferrer" href='/privacy-policy.html'>Privacy policy</a></li>
        <li><a target="_blank" rel="noreferrer" href='/acceptable-use-policy.html'>Acceptable use policy</a></li>
        <li><a target="_blank" rel="noreferrer" href='/terms-and-conditions.html'>Terms and conditions</a></li>
      </ul>
    </div>
  </div>
}

const createUserId = uuidv4;

function App() {
  let { variant } = useParams();
  let [ sendDataStatus, setSendDataStatus ] = useState(null);

  useEffect(() => {
    googleAnalyticsHeartbeat();
    let userId = window.localStorage.getItem('userId');
    if ( userId === null ){
      userId = createUserId();
      window.localStorage.setItem('userId', userId);
    }
    LogRocket.identify(userId)
  }, []);
  
  let sendData = (submittedData) => {
    setSendDataStatus('pending');
    let data = getDataToSend(submittedData);
    sendContactDataToPipedream(data)
      .then( () => {
        setPage('success')
        setSendDataStatus('success');
        logGoogleAdsConversion();
        window.gtag('event', 'successfully-submitted-form');
      }).catch(error => {
        console.error(error)
        setSendDataStatus('failure');
        window.gtag('event', 'network-error-submitting-form');
      });
  }

  let getDataToSend = (submittedData) => {
    let dataToSend = {
      name: (submittedData['contact-details'] || {}).name,
      email: (submittedData['contact-details'] || {}).email,
      url: window.location.href
    };
    pageFlow.filter(p => p!=='value-propositions')
      .forEach(p => dataToSend[p] = JSON.stringify(submittedData[p] || {}));
    return dataToSend;
  }

  let [pageData, setPageData] = useState({});
  const pageFlow = ['topics', 'buying-goal', 'looking-for', 'value-propositions', 'contact-details'];
  
  const getProportion = () => {
    if ( page === 'success' ){
      return 1;
    }
    let ix = pageFlow.indexOf(page);
    if ( ix === -1 ) return 0;
    return ix/pageFlow.length;
  }
  
  let submitPageFactory = page => response => {
    let newData = {...pageData, [page]: response}
    setPageData(newData);
    if ( page === pageFlow.slice(-1)[0] ){
      sendData(newData);
    } else if ( pageFlow.indexOf(page) === -1 ){
      setPage('topics')
    } else {
      setPage(pageFlow[pageFlow.indexOf(page) + 1]);
    }
  }

  let goBackOnePage = () => {
    if ( pageFlow.indexOf(page) === -1 || page === 'topics' ) {
      setPage('topics');
    } else {
      setPage(pageFlow[pageFlow.indexOf(page) - 1]);
    }
  }

  let [page, setPage] = useState('topics');
  const getPageElement = () => {
    switch(page){
      case 'buying-goal':
        return <QuestionBuyingGoal 
          variant={variant}
          buyingGoal={pageData['buying-goal']}
          goBackOnePage={goBackOnePage} 
          submitBuyingGoal={submitPageFactory('buying-goal')}/>
      case 'looking-for':
        return <QuestionLookingFor 
          lookingFor={pageData['looking-for']}
          goBackOnePage={goBackOnePage} 
          submitLookingFor={submitPageFactory('looking-for')}/>
      case 'value-propositions':
        return <ValuePropositionSummary 
          goBackOnePage={goBackOnePage} 
          onContinue={submitPageFactory('value-propositions')}/>
      case 'contact-details':
        return <AskContactDetails 
          contactDetails={pageData['contact-details']}
          goBackOnePage={goBackOnePage} 
          submitContactDetails={submitPageFactory('contact-details')} 
          sendDataStatus={sendDataStatus} />
      case 'success':
        return <SuccessPage variant={variant}/>
      case 'topics':
      default:
        return <QuestionTopics 
          topics={pageData['topics']}
          goBackOnePage={goBackOnePage} 
          submitTopics={submitPageFactory('topics')}/>
    }
  }

  useEffect(() => {
    let eventName = `go-to-page-${page}`
    window.gtag('event', eventName);
    LogRocket.track(eventName);
  }, [page]);

  return <div className='App'>
    <div className='contact-us-section'>
      <div className='contact-us-card'>
        <Header/>
        <HeroSection variant={variant}/>
        { getPageElement() }
        <ProgressBar proportion={getProportion()} min={20} max={100}/>
      </div>
    </div>
    <AboutUsSection/>
    <FooterSection />
  </div>
}

function ProgressBar({ proportion, max=100, min=10 }){
  return <div 
    className='progress-bar'
    data-proportion={proportion} 
    style={{width: `${Math.floor(min + ((max - min) * proportion))}%`}}></div>
}

export default App;


